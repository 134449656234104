<template>
  <div class="cpt-layout cpt-layout">
    <router-view />
  </div>
</template>

<script>
import CesiumLayer from "@/components/cesium-layer";
export default {
  components: {
    CesiumLayer,
  },
};
</script>

<style lang="scss" scoped>
.cpt-layout {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
</style>